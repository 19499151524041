.inputs {
  flex-grow: 1;
  margin-bottom: 3em;
}

.inputs > div:first-child {
  margin-bottom: 2em;
}

.inputs .label-wrapper {
  margin-bottom: 0.6em;
}

.inputs label {
  font-weight: 700;
}

.inputs textarea {
  border: 0;
  min-height: 27.2rem;
  width: 100%;
}
