.mouse {
  padding-left: 0.64em;
}

.mouse-body {
  border-radius: 7em 7em 8em 8em;
  box-shadow: inset 0 0 0 0.3em var(--other);
  height: 30rem;
  position: relative;
  width: 18rem;
}

.mouse-key {
  align-items: center;
  background-color: var(--dark);
  box-shadow: inset 0 0 0 0.1em var(--other);
  color: var(--other);
  display: flex;
  justify-content: center;
  position: absolute;
}

.mouse-key.bound {
  box-shadow: inset 0 0 0 0.3em var(--highlight);
  color: var(--highlight);
}

.mouse-key.bound.ctype-movement {
  box-shadow: inset 0 0 0 0.3em var(--movement);
  color: var(--movement);
}

.mouse-key.bound.ctype-slot {
  box-shadow: inset 0 0 0 0.3em var(--slot);
  color: var(--slot);
}

.mouse-key.bound.ctype-buy {
  box-shadow: inset 0 0 0 0.3em var(--buy);
  color: var(--buy);
}

.mouse-key.bound.ctype-action {
  box-shadow: inset 0 0 0 0.3em var(--action);
  color: var(--action);
}

.mouse-key.bound.ctype-comms {
  box-shadow: inset 0 0 0 0.3em var(--comms);
  color: var(--comms);
}

.mouse-key span {
  font-weight: 700;
}

.mouse-key-1,
.mouse-key-2 {
  height: 7em;
  width: 5.8em;
}

.mouse-key-1 {
  border-top-left-radius: 4.93em;
  top: 0;
  left: 0;
}

.mouse-key-2 {
  border-top-right-radius: 4.93em;
  top: 0;
  right: 0;
}

.mouse-key-3 {
  border-radius: 50%;
  height: 2em;
  left: 4.6em;
  top: 2.48em;
  width: 2em;
}

.mouse-key-up,
.mouse-key-down {
  left: 4.6em;
  height: 3em;
  width: 2em;
}

.mouse-key-up {
  border-top-left-radius: 1em;
  border-top-right-radius: 1em;
  top: 0.6em;
  width: 2em;
}

.mouse-key-down {
  border-bottom-left-radius: 1em;
  border-bottom-right-radius: 1em;
  top: 3.34em;
  width: 2em;
}

.mouse-key-4,
.mouse-key-5 {
  border-radius: 0.6em;
  height: 2.6em;
  left: -0.64em;
  width: 1.6em;
}

.mouse-key-4 {
  top: 7.5em;
}

.mouse-key-5 {
  top: 10.7em;
}