:root {
  font-size: 62.5%;
  --dark: #222;
  --light: #fff;
  --highlight: #e73;
  --movement: #c31;
  --slot: #07b;
  --buy: #098;
  --action: #3be;
  --comms: #e37;
  --other: #bbb;
  --font-family: Consolas, Arial, Helvetica, sans-serif;
}

html {
  box-sizing: border-box;
}

body {
  background-color: var(--dark);
  color: #fff;
  font-family: var(--font-family);
  font-size: 1.6rem;
  padding: 0;
}

*,
*:before,
:after {
  box-sizing: inherit;
}

::selection {
  background: var(--light);
  color: var(--movement);
}

a {
  color: var(--highlight);
}

a:active {
  color: var(--slot);
}

a:hover {
  color: var(--buy);
}

a:visited {
  color: var(--comms);
}


header {
  background-color: var(--light);
  color: var(--dark);
  margin-bottom: 6rem;
  padding: 1em 0;
}

header > div {
  align-items: center;
  margin: 0 auto;
  max-width: 75em;
  display: flex;
  justify-content: space-between;
}

h1 {
  margin: 0;
}

main {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 0 1em;
  width: fit-content;
}

.settings {
  align-items: center;
  display: flex;
}

.settings select {
  color: var(--dark);
  font-family: var(--font-family);
  font-size: 1.2em;
  font-weight: 700;
}

.settings label {
  font-size: 1.2em;
  font-weight: 700;
  margin-right: 0.4em;
}

.keyboard-wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.inputs-mouse-wrapper {
  display: flex;
  gap: 3em;
  justify-content: center;
}
